import { ZDTProduct, ZDTSEO } from '@zalora/doraemon-ts';
import { TFunction } from 'next-i18next';
import { QueryParams } from 'api/APIClient';
import { BreadcrumbItemType } from 'components/Breadcrumbs';
import { Routes } from 'constants/routes';
import { captureError } from 'utils/raven';
import { CatalogType, PageInfo } from './catalog-page-type';
import { getRootCategory, getSpecialPageLabel } from './catalog-seo';
import { buildBrandUrl } from './catalog-url';

export const sortCategoryByDepth = (categoryBreadcrumbs: ZDTSEO.CategoryBreadcrumb[]) => {
  if (!categoryBreadcrumbs) {
    return [];
  }

  return categoryBreadcrumbs.slice().sort((prev, curr) => Number(prev.Depth) - Number(curr.Depth));
};

export const getCategoryUrlBreadcrumbs = (
  category: ZDTProduct.BreadcrumbsDetailCategory | ZDTSEO.CategoryBreadcrumb,
  segment: ZDTProduct.BreadcrumbsDetailSegment | ZDTSEO.Segment | undefined | null,
  rootCategory: ZDTProduct.BreadcrumbsDetailCategory | ZDTSEO.CategoryBreadcrumb | undefined,
) => {
  if (!segment || !category) {
    return '';
  }

  let segmentKey: string;

  // ZDTProduct.BreadcrumbsDetailSegment
  if ('UrlKey' in segment && segment.UrlKey) {
    segmentKey = segment.UrlKey;
  }
  // Segment
  else if ('Key' in segment && segment.Key) {
    segmentKey = segment.Key;
  }
  // No valid segment
  else {
    return '';
  }

  switch (category.Depth) {
    case '1': {
      return `/${segmentKey}/${category.UrlKey}`;
    }

    case '2': {
      return `/c/${segmentKey}/${category.UrlKey}/c-${category.Id}`;
    }

    case '3':
    case '4':

    case '5': {
      if (!rootCategory) {
        return '';
      }

      return `/c/${segmentKey}/${rootCategory.UrlKey}/${category.UrlKey}/c-${rootCategory.Id}/scat-${category.Id}`;
    }

    default: {
      return '';
    }
  }
};

// Order: Home > [segment] > [root category] > [sub category] > [brand] > [special page]
export const getCatalogBreadcrumbs = (
  seoInfo: ZDTSEO.CatalogInfo | undefined,
  params: QueryParams,
  t: TFunction,
): BreadcrumbItemType[] => {
  const pageInfo = seoInfo?.PageInfo;

  const { Segment, CategoryBreadcrumbs, Brand } = pageInfo || {};

  const breadcrumbs: BreadcrumbItemType[] = [
    {
      label: t('Home'),
      url: Routes.HOMEPAGE,
    },
  ];

  if (Segment?.Name) {
    breadcrumbs.push({
      label: Segment.Name,
      url: `/s/${Segment.Key}`,
    });
  }

  if (CategoryBreadcrumbs?.length) {
    const sortedBreadcrumbs = sortCategoryByDepth(CategoryBreadcrumbs);
    const rootCategory = getRootCategory(!!seoInfo?.PageInfo?.Segment, seoInfo);

    sortedBreadcrumbs.forEach((breadcrumb) => {
      if (breadcrumb.Depth === '1') {
        // this is root cat without segment, should be ignore
        return;
      }

      try {
        const categoryUrl = getCategoryUrlBreadcrumbs(breadcrumb, Segment, rootCategory);

        if (categoryUrl && breadcrumb?.Name) {
          breadcrumbs.push({
            label: breadcrumb.Name,
            url: categoryUrl,
          });
        }
      } catch (error) {
        captureError('Error when generate category URL', {
          error,
          tag: 'get-request',
        });
      }
    });
  }

  if (Brand?.Name) {
    breadcrumbs.push({
      label: Brand.Name,
      url: buildBrandUrl(Brand),
    });
  }

  if (params.query) {
    const formattedQuery = params.query as string;

    breadcrumbs.push({
      label: formattedQuery,
      url: `/search?q=${formattedQuery}`,
    });
  }

  if (params?.specialKey) {
    const label = getSpecialPageLabel(`${params.specialKey}`, t);

    if (label) {
      breadcrumbs.push({
        label,
        url: '', // unclickable
      });
    }
  }

  return breadcrumbs;
};

export const shouldShowBreadcrumbs = (pageInfo: PageInfo) => {
  return [CatalogType.CATALOG, CatalogType.SEARCH].includes(pageInfo.catalogType);
};
