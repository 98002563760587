const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const CHARACTERS_LENGTH = CHARACTERS.length;

export const randomString = (length: number) => {
  let result = '';

  if (window.crypto && window.crypto.getRandomValues) {
    const values = new Uint32Array(length);

    window.crypto.getRandomValues(values);

    for (let i = 0; i < length; i++) {
      result += CHARACTERS[values[i] % CHARACTERS_LENGTH];
    }

    return result;
  }

  // Fall back if browser doesn't support window.crypto.getRandomValues
  for (let i = 0; i < length; i++) {
    result += CHARACTERS[Math.floor(Math.random() * CHARACTERS_LENGTH)];
  }

  return result;
};
