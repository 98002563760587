export { hasNumbersOnly } from './hasNumbersOnly';
export { randomString } from './randomString';
export { randomStringFromZuid } from './randomStringFromZuid';
export { sha256 } from './sha256';
export { extractVideoId } from './extractVideoId';
export { replacePlaceholdersWithValues } from './replacePlaceholdersWithValues';

const CHINESE_CHARACTERS_PATTERN = new RegExp('[\u4e00-\u9fff\u3400-\u4aff]'); // Unicode range for Chinese characters

export function isChineseCharacter(str: string) {
  return CHINESE_CHARACTERS_PATTERN.test(str);
}

export const copyTextToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
  } catch {}
};

export const capitalize = (str: string) => {
  if (!str) {
    return str;
  }

  return str.replace(
    /(?:[a-zA-Z\d:']+)/g,
    (input) => input[0].toUpperCase() + input.substring(1).toLowerCase(),
  );
};

export const trimWhitespace = (str: string) => {
  if (!str) {
    return str;
  }

  return str.replace(/\s+/g, ' ').trim();
};

export const maskUntilLastXCharacters = (input: string, numberOfCharacters: number) => {
  const unmaskedPortion = input.slice(-numberOfCharacters);
  const maskedLength = input.length - unmaskedPortion.length;

  if (maskedLength > 0) {
    return '*'.repeat(maskedLength) + unmaskedPortion;
  }

  return '*'.repeat(input.length);
};
