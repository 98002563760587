import { ZDTCustomer } from '@zalora/doraemon-ts';
import { randomString } from './randomString';
import { sha256 } from './sha256';

/**
 * generate a random string, either based on customer's Zuid or completely random
 */
export const randomStringFromZuid = async (user?: ZDTCustomer.Customer) => {
  if (!user?.Zuid) {
    return randomString(10);
  }

  const hash = await sha256(user.Zuid);

  return hash.substring(0, 10);
};
