export const enum SHIPMENT_TYPES {
  ConsignmentStock = 'Consignment Stock',
  CrossListing = 'Cross Listing',
  CrossDocking = 'Cross Docking',
  Dropshipping = 'Dropshipping',
  Warehouse = 'Warehouse',
  DropshippingExworks = 'Dropshipping Exworks',
  CustomerPickup = 'Customer Pickup',
  Giftcard = 'Giftcard',
  Virtual = 'Virtual',
  ElectronicDelivery = 'Electronic Delivery',
}
