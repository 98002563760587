import { ZDTProduct, ZDTSEO, ZDTCatalog } from '@zalora/doraemon-ts';
import { TFunction } from 'i18next';
import { QueryParams } from 'api/APIClient';
import { NUMBER_OF_ITEMS_PLACEHOLDER } from 'constants/catalog';
import { CatalogType, PageInfo, SPECIAL_PAGES } from 'utils/catalog/catalog-page-type';
import { isAccountPage, isCartPage, isWishlistPage } from 'utils/page-type';
import { trimWhitespace } from 'utils/string';
import { getCleanQuery } from './query';
import { Robots, RootCategoryDepth } from './types';

export const getCanonical = (path: string, pageInfo: PageInfo, query?: QueryParams) => {
  let pathname = path.split('?')[0];

  if (pageInfo.specialPage === 'all') {
    pathname = pathname
      .split('/')
      .filter((chunk) => chunk !== 'all')
      .join('/');
  }

  return `${pathname}${query?.page ? `?page=${query.page}` : ''}`;
};

/**
 * TRUE:
 * - if it is sale page
 * - if it is sale page + segment
 *
 * FALSE
 * - if it is sale page + (category || brand)
 */

const isSalePage = (pageInfo: PageInfo) => {
  if (pageInfo.categoryId || pageInfo.brandId) {
    return false;
  }

  return pageInfo.specialPage === SPECIAL_PAGES.SALE;
};

export const getRobots = (
  routerQuery: QueryParams,
  pageInfo: PageInfo,
  defaultValue = Robots.INDEX_FOLLOW,
) => {
  if (isCatalogOfSegment(pageInfo)) {
    return Robots.NOINDEX_NOFOLLOW;
  }

  const query = getCleanQuery(routerQuery);
  const queryLength = Object.keys(query).length;

  // Don't index, but follow if having query params except page
  if (queryLength > 1 || (queryLength === 1 && !query.page)) {
    return Robots.NOINDEX_FOLLOW;
  }

  // Don't index, but follow special pages except SALE page
  if (!!pageInfo.specialPage && !isSalePage(pageInfo)) {
    return Robots.NOINDEX_FOLLOW;
  }

  return defaultValue;
};

export const getRobotsByPage = (url: string) => {
  if (isWishlistPage(url) || isCartPage(url) || isAccountPage(url)) {
    return Robots.NOINDEX_NOFOLLOW;
  }

  return '';
};

export const getOrderCategories = (seoInfo: ZDTSEO.CatalogInfo) => {
  const { PageInfo } = seoInfo;
  const { CategoryBreadcrumbs } = PageInfo || {};

  if (!CategoryBreadcrumbs || CategoryBreadcrumbs.length === 0) {
    return [];
  }

  return CategoryBreadcrumbs.sort((c1, c2) => Number(c1.Depth) - Number(c2.Depth));
};

export const getRootCategory = (isRootForSegment: boolean, seoInfo?: ZDTSEO.CatalogInfo) => {
  const rootDepth = isRootForSegment
    ? RootCategoryDepth.WITH_SEGMENT
    : RootCategoryDepth.WITHOUT_SEGMENT;

  return seoInfo?.PageInfo?.CategoryBreadcrumbs?.find((category) => category.Depth === rootDepth);
};

export const getLastChildCategory = (seoInfo?: ZDTSEO.CatalogInfo) => {
  if (!seoInfo) {
    return undefined;
  }

  const sortedCategories = getOrderCategories(seoInfo);

  return sortedCategories[sortedCategories.length - 1];
};

export const getSpecialPageLabel = (specialPage: string, t: TFunction, defaultText?: string) => {
  switch (specialPage) {
    case SPECIAL_PAGES.SALE: {
      return t('Sales');
    }

    case SPECIAL_PAGES.NEW: {
      return t('New Arrivals');
    }

    case SPECIAL_PAGES.ALL: {
      return t('All Products');
    }

    case SPECIAL_PAGES.TOP: {
      return t('Top selling');
    }

    default: {
      return defaultText || '';
    }
  }
};

export const getH1Content = ({
  pageInfo,
  numOfProduct,
  seoInfo,
  t,
}: {
  pageInfo: PageInfo;
  seoInfo: ZDTSEO.CatalogInfo;
  numOfProduct: Nullishable<number>;
  t: TFunction;
}) => {
  switch (pageInfo.catalogType) {
    case CatalogType.SEARCH: {
      return t('Search results for');
    }

    case CatalogType.ZIS: {
      return '';
    }

    case CatalogType.PIP: {
      return pageInfo.metaData?.H1Tag || pageInfo.productIndexInfo.Title;
    }

    case CatalogType.CATALOG: {
      const h1 = seoInfo?.H1;

      if (!h1) {
        return getSpecialPageLabel(pageInfo.specialPage as string, t, t('Home'));
      }

      if (typeof numOfProduct === 'number' && h1.includes(NUMBER_OF_ITEMS_PLACEHOLDER)) {
        return h1.replace(NUMBER_OF_ITEMS_PLACEHOLDER, numOfProduct.toLocaleString());
      }

      return h1;
    }
  }
};

export const getPageName = (
  pageInfo: PageInfo,
  seoInfo: ZDTSEO.CatalogInfo,
  t: TFunction<'catalog', undefined, 'catalog'>,
) => {
  switch (pageInfo.catalogType) {
    case CatalogType.PIP: {
      return pageInfo.productIndexInfo.Title;
    }

    case CatalogType.CATALOG: {
      const category = getLastChildCategory(seoInfo);
      const { Brand, Segment } = seoInfo.PageInfo || {};

      const pageName = t('SEO page name', {
        brand: Brand?.Name,
        // If it is root category, the segment already included in the category name, don't need to show it again
        segment: (Number(category?.Depth) || 0) > 2 ? Segment?.Name : undefined,
        category: category?.Name,
      });

      return pageName.replace(/\s+/g, ' ').trim();
    }

    default: {
      return '';
    }
  }
};

/**
 * Catalog of segment is a catalog page which having segment info only
 * example: `/c/[segment]`
 */
const isCatalogOfSegment = (pageInfo: PageInfo) => {
  return (
    pageInfo.catalogType === CatalogType.CATALOG &&
    pageInfo.segment &&
    Object.keys(pageInfo).length === 2 // only having segment and catalogType
  );
};

export const getReviewInfo = (
  sponsoredProducts: Nullishable<ZDTProduct.Product[]>,
  products: Nullishable<ZDTCatalog.Product[]>,
) => {
  const getAvgRating = (
    products: Nullishable<ZDTProduct.Product[]> | Nullishable<ZDTCatalog.Product[]>,
  ) => {
    // Below code is working fine, but will fail ts, it is known issue of ts
    // https://github.com/microsoft/TypeScript/issues/44373
    // It has been fixed by ts 5.2.2, but will not work in our project by version 4.8.4
    // https://www.typescriptlang.org/play?ts=4.8.4 vs
    // https://www.typescriptlang.org/play?ts=5.2.2
    // will ignore
    // @ts-ignore
    const product = products?.find((product) => product.ReviewStatistics?.AvgRating);

    return product?.ReviewStatistics?.AvgRating || 0;
  };

  const getTotalReviews = (
    products: Nullishable<ZDTProduct.Product[]> | Nullishable<ZDTCatalog.Product[]>,
  ) =>
    // @ts-ignore
    (products || []).reduce(
      (acc: number, product: ZDTProduct.Product | ZDTCatalog.Product) =>
        acc + (product.ReviewStatistics?.ReviewCount || 0),
      0,
    );

  const avgRating = getAvgRating(sponsoredProducts) || getAvgRating(products);
  const totalReviews = getTotalReviews(sponsoredProducts) + getTotalReviews(products);

  return {
    avgRating,
    totalReviews,
  };
};

export const getReviewTitle = (seoInfo: ZDTSEO.CatalogInfo, t: TFunction) => {
  const { PageInfo } = seoInfo;

  if (!PageInfo) {
    return '';
  }

  const category = getLastChildCategory(seoInfo);
  const { Brand, Segment } = PageInfo;

  const title = t('Shopping Review of {{category}} {{brand}} {{segment}} online at ZALORA', {
    category: category?.Name,
    brand: Brand?.Name,
    // incase of root cat (depth = 2), category name already included segment
    segment: category?.Depth === '2' ? undefined : Segment?.Name,
    ns: 'catalog',
  });

  return trimWhitespace(title);
};
