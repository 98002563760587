import { ZDTProduct, ZDTSEO, ZDTCatalog } from '@zalora/doraemon-ts';
import { PageInfo } from './catalog/catalog-page-type';
import { getRootCategory } from './catalog/catalog-seo';

export const getSourceSegment = (product: ZDTCatalog.Product | ZDTProduct.Product) => {
  return product.Breadcrumbs?.[0];
};

export const getCategories = (product: ZDTCatalog.Product | ZDTProduct.Product) => {
  if (!product || !product.Breadcrumbs) {
    return [];
  }

  // Get all element from 3rd element to end as categoriess
  return product.Breadcrumbs.slice(2);
};

export const getCategory = (product: ZDTCatalog.Product | ZDTProduct.Product) => {
  const categories = getCategories(product);

  if (!categories.length) {
    return '';
  }

  return categories[0];
};

export const getSubcategory = (product: ZDTCatalog.Product) => {
  const categories = getCategories(product);

  if (!categories.length) {
    return '';
  }

  // Deepest level child category
  return categories[categories.length - 1] ?? '';
};

/**
 * Get breadcrumbs as string for tracking purpose
 */
export const getSubcategoryBreadcrumbs = (product: ZDTCatalog.Product) => {
  const breadcrumbs = product.Breadcrumbs || [];

  return breadcrumbs.map((item) => item).join(' > ');
};

export const isValidSeoInfo = ({
  pageInfo,
  seoInfo,
}: {
  pageInfo: PageInfo;
  seoInfo?: ZDTSEO.CatalogInfo;
}) => {
  // Only need to validate on brand, category, subcategory page
  if (!(pageInfo.brandId || pageInfo.categoryId || pageInfo.subCategoryId)) {
    return true;
  }

  if (!seoInfo) {
    return false;
  }

  if (pageInfo.brandId && !isValidBrandPage(pageInfo, seoInfo)) {
    return false;
  }

  if (pageInfo.categoryId && !isValidCategoryPage(pageInfo, seoInfo)) {
    return false;
  }

  if (pageInfo?.subCategoryId && !isValidSubCategoryPage(pageInfo, seoInfo)) {
    return false;
  }

  return true;
};

const isValidBrandPage = (pageInfo: PageInfo, seoInfo?: ZDTSEO.CatalogInfo) => {
  return pageInfo.brandId === seoInfo?.PageInfo?.Brand?.Id;
};

const isValidCategoryPage = (pageInfo: PageInfo, seoInfo?: ZDTSEO.CatalogInfo) => {
  const rootCategory = getRootCategory(!!pageInfo.segment, seoInfo);

  return pageInfo.categoryId === rootCategory?.Id;
};

const isValidSubCategoryPage = (pageInfo: PageInfo, seoInfo?: ZDTSEO.CatalogInfo) => {
  const { CategoryBreadcrumbs = [] } = seoInfo?.PageInfo || {};
  const subCat = CategoryBreadcrumbs?.find((item) => item.Id === pageInfo.subCategoryId);

  if (!subCat?.Depth || Number(subCat.Depth) < 3) {
    return false;
  }

  return true;
};
