import { ZDTSEO } from '@zalora/doraemon-ts';

export const TRACKING = {
  LIST_IDS: {
    PRODUCT_DETAILS: 'Product Details',
    WISHLIST: 'Wishlist',
    CART: 'Cart',
    ZIS: 'ZIS',
  },
  STATIC_DATA_SOURCE: 'Static',
  STATIC: 'static',
} as const;

export interface TrackingDataProps {
  activeSegment: string | undefined;
  seoInfo?: ZDTSEO.CatalogInfo;
  source_page_type?: string;
}

export const enum ENV_TYPE_DEVICE {
  ENV_TYPE_MOBILE = 'Lotus Mobile Web',
  ENV_TYPE_DESKTOP = 'Lotus Desktop Web',
}
export const enum PROMOTION_EVENT_TYPE {
  PROMOTION_VIEWED = 'Promotion Viewed Banner Level',
  PROMOTION_CLICKED = 'Promotion Clicked Banner Level',
  ARRAY_PROMOTION_VIEWED = 'Promotion Viewed',
  ARRAY_PROMOTION_CLICKED = 'Promotion Clicked',
}

export default TRACKING;

export const RECOMMENDED_FOR_YOU = 'Recommended for you';
export const SPONSORED_ADS = 'Sponsored Ads';
export const REC_FEED_PRODUCT_VIEWED = 'RecFeed Product Viewed';
export const REC_FEED_PRODUCT_CLICKED = 'RecFeed Product Clicked';
export const REC_FEED_PRODUCT_ADDED_TO_WISHLIST = 'RecFeed Product Added to Wishlist';
export const REC_FEED_PRODUCT_VIEWED_ITEM_LEVEL = 'RecFeed Product Viewed Item Level';
export const REC_FEED_PRODUCT_CLICKED_ITEM_LEVEL = 'RecFeed Product Clicked Item Level';
export const REC_FEED_PRODUCT_ADDED_TO_WISHLIST_ITEM_LEVEL =
  'RecFeed Product Added to Wishlist Item Level';
export const REC_FEED_PRODUCT_ADDED_TO_BAG = 'RecFeed Product Added to Bag';
export const REC_FEED_PRODUCT_ADDED_TO_BAG_ITEM_LEVEL = 'RecFeed Product Added to Bag Item Level';

export const SOURCE_CATALOG_DATA = {
  Brand: 'Brand',
  Category: 'Category',
  Search: 'Search',
  Campaign: 'Campaign',
  ZIS: 'ZIS',
  PIP: 'Product Index',
};

export const enum PRODUCT_LIST_FILTER_LOCATION {
  ONE_CLICK = '1-click filter',
  MID_CATALOG = 'Mid-catalog filter',
  NORMAL = 'Normal filter',
}

export const enum SegmentEvent {
  CLICK,
  IMPRESSION,
  ADD_TO_WISHLIST,
}

export const enum RecFeedLayout {
  ROW = 'Row',
  LIST = 'List',
  GRID = 'Grid',
}

// Position constants for PDV Recfeed
export const SPONSORED_ADS_POSITION = 1; // Sponsored ads position

export const enum SERIAL_RETURNER_CARD_EVENT_TRIGGER {
  CLICKED = 'Clicked',
  VIEWED = 'Viewed',
}
